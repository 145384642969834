angular.module("app")
    .constant("_calendarItemGapInPx", 5)
    .constant("_calendarItemMinWidthInPx", 150)
    .factory("_calendar", function ($rootScope, $timeout, $window, $q, _v, _view, _calendarItemGapInPx, _calendarItemMinWidthInPx, sowingsStages) {

        //calendar public model. holds public api and public properties
        var answer = {
            init: init,
            getPositionForDatePromise: getPositionForDatePromise,
            getDateForPositionPromise: getDateForPositionPromise,
            getDisplayedDateBoundariesPromise: getDisplayedDateBoundariesPromise,
            isGranularityStages: isGranularityStages,
            fallbackToMonthGranularity: fallbackToMonthGranularity //TODO check
            //from
            //normalizedFrom
            //granularity
            //itemsCount
            //itemWidthInPx
            //itemsPromise
        };

        /**
         * Calendar parameters changed callback - start date, granularity or columns count
         */
        function onChange() {
            refreshItems();
            $rootScope.$broadcast("_calendar:changed");
        }

        /**
         * Calendar only width change callback
         */
        function onWidthChange() {
            $rootScope.$broadcast("_calendar:width-changed");
        }

        /**
         * Calendar params change
         *
         * @param params new params
         * @param changed true to force onChange() event.
         */
        function setParams(params, changed = false) {

            //granularity should be before from
            if (params.g != answer.granularity) {
                answer.granularity = params.g;
                changed = true;
            }

            if (!params.from.isSame(answer.from)) {
                answer.from = params.from;
                changed = true;
            }

            if (changed) {
                onChange();
            }
        }

        /**
         * calendar columns count and column width refresh
         *
         * fills model bean answer
         * answer.itemsCount count of columns
         * answer.itemWidthInPx item width in pixels
         *
         * @returns {boolean} true if items count changed
         */
        function refreshItemsCountAndItemWidth() {
            var current = answer.itemsCount;

            var windowWidth = $window.innerWidth;
            var width = _calendarItemGapInPx * 2;
            var itemsCount = 0;

            while (width < windowWidth) {
                width += _calendarItemMinWidthInPx;
                itemsCount++;
            }

            itemsCount--;

            answer.itemsCount = itemsCount;
            answer.itemWidthInPx = (windowWidth - _calendarItemGapInPx * itemsCount) / itemsCount;

            return answer.itemsCount != current;
        }

        /**
         * Calendar dimensions are known here.
         * Refresh here columns models - dates, date labels
         */
        function refreshItems() {
            answer.itemsPromise = generateItemsPromise();
        }

        /**
         * Returns date position as percent from screen left
         *
         * @param d date
         * @returns position promise (position in percent or null)
         */
        function getPositionForDatePromise(d) {
            var date = moment.utc(d);
            return answer.itemsPromise.then(function(items) {

                var result = null;
                _.each(items, function(item, index){
                    if (item) { //ignore null columns
                        if (date.isBetween(item.date, item.dateFinish, null, '[]')) {
                            var columnTotalDuration = item.dateFinish.diff(item.date);
                            var dateDuration = date.diff(item.date);
                            var columnWidthInPercent = (100 / answer.itemsCount);
                            result = columnWidthInPercent * index + columnWidthInPercent * (dateDuration / columnTotalDuration);
                        }
                    }
                });

                return result;
            });
        }

        /**
         * Returns pixel position as date
         *
         * @param position in pixels
         * @returns date promise
         */
        function getDateForPositionPromise(position) {
            var p = position;
            return answer.itemsPromise.then(function(items) {
                var result = null;
                var k = p / (answer.itemWidthInPx  + _calendarItemGapInPx);
                var columnIndex = Math.floor(k);
                if (columnIndex < items.length) {
                    var column = items[columnIndex];
                    if (column) {

                        var columnTotalDuration = column.dateFinish.diff(column.date);
                        var position = columnTotalDuration * (k - columnIndex);

                        result = column.date.clone().add(position, 'ms');
                    }
                }
                return result;
            });
        }

        /**
         * Returns screen date boundaries.
         * For stage granularity boundaries can end before last column.
         * @returns {from, to} or null result promise
         */
        function getDisplayedDateBoundariesPromise() {
            return answer.itemsPromise.then(function(items) {
                var firstItem = _.find(items, function(item) { return item != null; });
                if (firstItem != null) {
                    var lastItem = _.find(angular.copy(items).reverse(), function(item) { return item != null; });
                    return {
                        from: firstItem.date.clone(),
                        to: lastItem.dateFinish.clone()
                    };
                } else { //all null items case
                    return null;
                }
            });
        }

        function generateItemsPromise() {
            var itemsCount = answer.itemsCount;
            var granularity = answer.granularity;
            answer.normalizedFrom = normalizeFrom(answer.from);

            if (answer.granularity == 's') { //move from param to stages dates
                sowingsStages.list(_view.getCurrentAccount(), _view.getCurrentField().id).then(function(dates) {
                    var stages = _.flatten(dates);
                    if (_.size(stages) > 0) {
                        var columnNum = null;

                        _.each(stages, function (stage, i) {
                            var start = moment.utc(stage.start);
                            var finish = moment.utc(stage.end);
                            if (answer.normalizedFrom.isBetween(start, finish, null, '[]')) {
                                columnNum = i;
                            }
                        });

                        if (columnNum != null) {
                            if (columnNum + answer.itemsCount > _.size(stages)) {
                                var max = Math.max(0, _.size(stages) - answer.itemsCount);
                                _v.change({set: {"from": stages[max].start}});
                            }
                        } else {
                            if (answer.normalizedFrom.isBefore(stages[0].start)) {
                                _v.change({set: {"from": stages[0].start}});
                            } else {
                                var max = Math.max(0, _.size(stages) - answer.itemsCount);
                                _v.change({set: {"from": stages[max].start}});
                            }
                        }
                    }
                });
            }

            //console.log("from and normalizedFrom", answer.from.format(), answer.normalizedFrom.format());
            var generate;

            if (granularity == "h") {
                generate = function (i) {
                    var deferred = $q.defer();
                    var date = answer.normalizedFrom.clone().add(i, "hours");
                    var dateFinish = answer.normalizedFrom.clone().add(i+1, "hours").subtract(1, "ms");

                    deferred.resolve({
                        position: i,
                        date: date,
                        dateFinish: dateFinish,
                        current: answer.from.isBetween(date, dateFinish, null, '[]'),
                        label: date.format("ha")
                    });

                    return deferred.promise;
                };
            } else if (granularity == "d") {
                generate = function (i) {
                    var deferred = $q.defer();
                    var date = answer.normalizedFrom.clone().add(i, "days");
                    var dateFinish = answer.normalizedFrom.clone().add(i+1, "days").subtract(1, "ms");

                    deferred.resolve({
                        position: i,
                        date: date,
                        dateFinish: dateFinish,
                        current: answer.from.isBetween(date, dateFinish, null, '[]'),
                        label: date.format("D") + ' <span>' + date.format("ddd") + '</span>'
                    });

                    return deferred.promise;
                };
            } else if (granularity == "w") {
                generate = function (i) {
                    var deferred = $q.defer();
                    var date = answer.normalizedFrom.clone().add(i * 7, "days");
                    var dateFinish = answer.normalizedFrom.clone().add((i+1)* 7, "days").subtract(1, "ms");

                    deferred.resolve({
                        position: i,
                        date: date,
                        dateFinish: dateFinish,
                        current: answer.from.isBetween(date, dateFinish, null, '[]'),
                        label: date.format("D/M")
                    });

                    return deferred.promise;
                };
            } else if (granularity == "m") {
                generate = function (i) {
                    var deferred = $q.defer();
                    var date = answer.normalizedFrom.clone().add(i, "months");
                    var dateFinish = answer.normalizedFrom.clone().add(i+1, "months").subtract(1, "ms");

                    deferred.resolve({
                        position: i,
                        date: date,
                        dateFinish: dateFinish,
                        current: answer.from.isBetween(date, dateFinish, null, '[]'),
                        label: date.format("MMMM")
                    });

                    return deferred.promise;
                };
            }
            else if (granularity == "s") {
                generate = function (i) {
                    var iGen = function(i, dates) {
                        if (i >= dates.length) {
                            return null; //this is empty column
                        }

                        var date = moment.utc(dates[i].start);
                        var dateFinish = moment.utc(dates[i].end);

                        return {
                            position: i,
                            date: date,
                            dateFinish: dateFinish,
                            current: answer.from.isBetween(date, dateFinish, null, '[]'),
                            label: date.format("D"),
                            stageName: dates[i].name
                        };
                    };


                    //todo remove _v.$v.field
                    return sowingsStages.getStagesDatesStartEndFrom(_view.getCurrentAccount(), _v.$v.field, answer.normalizedFrom.format()).then(function(dates) {
                        var iItem = iGen(i, dates);
                        return iItem;
                    });
                };
            }

            var itemPromises = [];

            _.times(itemsCount, function (i) {
                itemPromises.push(generate(i));
            });

            return $q.all(itemPromises);
        }

        function init() {

            $rootScope.$on('_view:urlReady', function() {

                //bind resize only after urlReady
                (function () {
                    angular.element($window).bind('resize', function () {
                        var itemsCountChanged = refreshItemsCountAndItemWidth();

                        if (itemsCountChanged) {
                            onChange();
                        } else {
                            onWidthChange();
                        }
                        return true;
                    });
                })();

                refreshItemsCountAndItemWidth();

                setParams({
                    from: _view.getFrom(),
                    g: _view.getGranularity()
                }, true); //true to force calendar to redraw
            });

            $rootScope.$on('_view:fromChanged', function() {
                setParams({
                    from: _view.getFrom(),
                    g: _view.getGranularity()
                });
            });

            $rootScope.$on("_view:fieldChanged", function () {
                refreshItems();  // regenerate calendar items with sowing stages of the new field
            });
        }


        /**
         * Align "from" url parameter to current granularity to the middle of screen
         *
         * @param from url parameter
         * @returns aligned copy of from parameter
         */
        function normalizeFrom(from) {
            var firstDayOfWeek = 0; // Sunday by default
            var startOf;
            var subtract;
            var middleItem = Math.round(answer.itemsCount / 2) - 1;
            var granularity = answer.granularity;

            if (_view.getCurrentAccount()) {
                firstDayOfWeek = _view.getCurrentAccountInfo().settings.firstDayOfWeek || 0;
            }

            if (granularity == "h") {
                startOf = subtract = "hour";
            } else if (granularity == "d") {
                startOf = subtract = "day";
            } else if (granularity == "w") {
                startOf = "isoweek";
                subtract = 'week';

                // Set first day of the week. 0-6 (Sunday to Saturday)
                moment.updateLocale(moment.locale(), {week: {dow: firstDayOfWeek}});
            } else if (granularity == "m") {
                startOf = subtract = "month";
            } else if (granularity == 's') {
                middleItem = 0;
                startOf = subtract = "hour"; //minor from normalization. all logic is inside sowingsStages.getStagesDatesStartEndFrom
            }

            // Make a new instance of MomentJS to apply updated locale settings
            //from = moment.utc(from.valueOf());

            // Handle local time
            //from.add(moment().utcOffset(), 'minutes');

            // Normalize
            from = from.clone();

            from = from.startOf(startOf);

            // Make it centered
            from = from.subtract(middleItem, subtract);

            return from;
        }

        function isGranularityStages() {
            return answer.granularity === 's';
        }

        //TODO check
        function fallbackToMonthGranularity() {
            if (isGranularityStages()) {
                setParams({
                    from: _view.getFrom(),
                    g: 'm'
                });
                _v.change({set: {"granularity": 'm'}});  // if switched to farm view with stages granularity - fall back to months
            }
        }


        return answer;
    })
    .run(function (_calendar) {
        _calendar.init();
    })
    .directive("cCalendar", function ($q, _calendar, _v, $timeout, sowingsStages) {
        return {
            restrict: "E",
            templateUrl: "t-c-calendar",
            scope: {},
            link: function ($scope) {

                $scope.prevDate = function () {
                    getPrevDatePromise().then(function(prevDate) {
                       setFrom(prevDate);
                    });
                };

                $scope.nextDate = function () {
                    getNextDatePromise().then(function(nextDate) {
                        setFrom(nextDate);
                    });
                };

                $scope.$on("_calendar:changed", function () {
                    refreshModel();
                });

                $scope.$on("_view:fieldChanged", refreshModel);

                $scope.$on("_map:photoNotesReady", function(event, photoNotes) {
                    _.each($scope.items, function(item) {
                        item.hasPhotoNote = false;
                    });
                    _.each(photoNotes, function(photoNote){
                        _.each($scope.items, function(item) {
                            if (moment.utc(photoNote.noteDate).isBetween(item.date, item.dateFinish, "[]")) {
                                item.hasPhotoNote = true;
                            }
                        });
                    });
                });

                function refreshModel() {
                    $scope.bound = false;
                    $scope.items = [];

                    _calendar.itemsPromise.then(function(items) {
                        $scope.items = items;

                        $timeout(function() {
                            $scope.bound = true; // force angular to re-bind values
                        });
                    });


                }

                function getPrevDatePromise() {
                    return getDateChangePromise("prev").then(function(change) {
                        return _calendar.from.clone().subtract(change);
                    });
                }

                function getNextDatePromise() {
                    var promise = getDateChangePromise("next").then(function(change) {
                        return _calendar.from.clone().add(change);
                    });

                    var resultPromise = promise;

                    if (_calendar.granularity == 's') {
                        resultPromise = sowingsStages.countStagesFromDate(_v.$v.account, _v.$v.field, _calendar.from.format()).then(function(count) {
                            if (count > _calendar.itemsCount) {
                                return promise;
                            } else {
                                return _calendar.from.clone().add({days: 0});
                            }
                        });
                    }

                    return resultPromise;
                }

                function getDateChangePromise(direction) {
                    var dateChangePromise;
                    if (_calendar.granularity == "s") {
                        var stagesChangePromise;
                        if (direction == "prev") {
                            stagesChangePromise = sowingsStages.getDaysFromDateToPreviousStage(_v.$v.account, _v.$v.field, _calendar.from.format());
                        }
                        if (direction == "next") {
                            stagesChangePromise = sowingsStages.getDaysFromDateToNextStage(_v.$v.account, _v.$v.field, _calendar.from.format());
                        }
                        dateChangePromise = stagesChangePromise.then(function(days) {
                            return {days: days};
                        });
                    } else {
                        dateChangePromise = $q.when(getDateChange());
                    }

                    return dateChangePromise;
                }

                function getDateChange() {
                    var change;

                    if (_calendar.granularity == "h") {
                        change = {hours: 1};
                    } else if (_calendar.granularity == "d") {
                        change = {days: 1};
                    } else if (_calendar.granularity == "w") {
                        change = {weeks: 1};
                    } else if (_calendar.granularity == "m") {
                        change = {months: 1};
                    }

                    return change;
                }

                function setFrom(moment) {
                    _v.change({set: {"from": moment.format("YYYY-MM-DDTHH")}});
                }
            }
        };
    })
    .directive("cCalendarToday", function (_calendar, _i18n) {
        return {
            restrict: "E",
            templateUrl: "t-c-calendar-today",
            link: function ($scope, $element) {

                $scope.$on("_calendar:changed", function () {
                    refreshModel();
                });

                function refreshModel() {
                    var now = moment.utc();
                    var granularity = _calendar.granularity;
                    var labelFormat = "D/M";
                    var sub = _i18n.getString("calendar.today");

                    _calendar.getPositionForDatePromise(now).then(function(pos) {
                        if (pos) {
                            $element.show();
                            $element.css({left: pos + "%"});

                            if (granularity == "h") {
                                labelFormat = "hh:mm";
                                sub = now.format("a");
                            }

                            $scope.label = now.format(labelFormat);
                            $scope.sub = sub;
                        } else {
                            $element.hide();
                        }
                    });
                }
            }
        };
    })
    .directive("cCalendarDateHint", function (_calendar) {
        return {
            controllerAs: 'dateHint',
            controller: function($scope) {
                var vm = this;

                $scope.$on("_calendar:changed", refreshModel);

                $scope.$on("_view:fieldChanged", refreshModel);

                function refreshModel() {
                    vm.items = [];
                    _calendar.itemsPromise.then(function(items) {
                        var items = _.map(items, _.clone);

                        var middleItem;
                        var middleItemYear;
                        var middleItemMonth;
                        var middleItemDay;
                        var caughtItems;
                        var date;
                        var labelFormat;

                        if (_calendar.isGranularityStages()) {
                            caughtItems = [items[0]];
                        } else {
                            middleItem = items[Math.round(items.length / 2) - 1];
                            caughtItems = [middleItem];
                            middleItemYear = middleItem.date.year();
                            middleItemMonth = middleItem.date.month();
                            middleItemDay = middleItem.date.day();
                        }

                        items.forEach(function(item, index) {
                            if (item) {
                                date = moment.utc(item.date);

                                // If granularity is set to 'month'
                                // And it's December or January
                                // And item.year is not Middle Item Year
                                if (_calendar.granularity == 'm' &&
                                    (date.month() == 0 || date.month() == 11) &&
                                    date.year() != middleItemYear
                                ) {
                                    caughtItems.push(item);
                                    return false;
                                }

                                // If granularity is set to 'weeks'
                                // And item.month is not Middle Item Month
                                // And it's the last (or the first) month in the year (I check if next item is next or previous month)
                                if (_calendar.granularity == 'w' &&
                                    (date.month() == 0 || date.month() == 11) &&
                                    date.year() != middleItemYear &&
                                    (!_.isUndefined(items[index + 1]) && items[index + 1].date.year() == middleItemYear ||
                                    !_.isUndefined(items[index - 1]) && items[index - 1].date.year() == middleItemYear)
                                ) {
                                    caughtItems.push(item);
                                    return false;
                                }

                                // If granularity is set to 'days'
                                // And item.month is not Middle Item Month
                                // And it's the last (or the first) day in the year (I check if next item is next or previous month)
                                if (_calendar.granularity == 'd' &&
                                    date.month() != middleItemMonth &&
                                    (!_.isUndefined(items[index + 1]) && items[index + 1].date.month() == middleItemMonth ||
                                    !_.isUndefined(items[index - 1]) && items[index - 1].date.month() == middleItemMonth)
                                ) {
                                    caughtItems.push(item);
                                    return false;
                                }

                                // If granularity is set to 'hours'
                                // And item.day is not Middle Item Day
                                // And it's the last (or the first) hour in the day (I check if next item is next or previous day)
                                if (_calendar.granularity == 'h' &&
                                    date.day() != middleItemDay &&
                                    (!_.isUndefined(items[index + 1]) && items[index + 1].date.day() == middleItemDay ||
                                    !_.isUndefined(items[index - 1]) && items[index - 1].date.day() == middleItemDay)
                                ) {
                                    caughtItems.push(item);
                                    return false;
                                }

                                // If granularity is set to 'stages'
                                if (_calendar.granularity == 's') {
                                    //     var isBoundary = index == 0 || index == items.length-1;
                                    var isChangingMonth = index > 0 ? date.month() != moment.utc(items[index - 1].date).month() : false;

                                    if (index == items.length - 1 || isChangingMonth) {
                                        caughtItems.push(item);
                                        return false;
                                    }
                                }
                            }
                        });

                        caughtItems.forEach((item, index) => {
                            if (item) {
                                // Set item label depending on granularity
                                if (_calendar.granularity == 'm') {
                                    labelFormat = "YYYY";
                                } else if (_calendar.granularity == 'w') {
                                    labelFormat = "YYYY";
                                } else if (_calendar.granularity == 'd') {
                                    labelFormat = "MMMM";
                                } else if (_calendar.granularity == 'h') {
                                    labelFormat = "DD MMM YYYY"
                                } else if (_calendar.granularity == 's') {
                                    if (index == 0 || index == caughtItems.length - 1) {
                                        labelFormat = "MMMM YY";
                                    } else {
                                        labelFormat = "MMMM";
                                    }
                                }

                                item.label = item.date.format(labelFormat);

                                // Calculate element position
                                item.left = ((item.position + 0.5) / _calendar.itemsCount) * 100 + "%";
                            }
                        });

                        vm.items = caughtItems;
                    });
                }
            }
        };
    })
    .directive("cCalendarGranularityManager", function (_calendar, _v, _i18n, _view) {
        return {
            restrict: "E",
            templateUrl: "t-c-calendar-granularity-manager",
            link: function ($scope) {
                $scope.granularities = {
                    items: ["s", "m", "w", "d", "h"].map(item => {
                        return {
                            key: item,
                            title: _i18n.getString("calendar.granularities." + item)
                        }
                    }),
                    current: null,
                    next: null,
                    prev: null
                };

                $scope.$on("_calendar:changed", function () {
                    updateNavigation();
                });

                $scope.prev = function() {
                    setGranularity($scope.granularities.prev);
                };

                $scope.next = function() {
                    setGranularity($scope.granularities.next);
                };

                $scope.isNextDisabled = function() {
                    var activeField;
                    if (_view.isFarmAndFieldSelected() || _view.isFieldSelected()) {
                        activeField = _view.getCurrentField();
                    }
                    return !$scope.granularities.next || (!activeField && _calendar.granularity == 'm');
                };

                $scope.isResetDisabled = function() {
                    return _calendar.isGranularityStages();
                };

                $scope.resetDate = function() {
                    _v.change({set: {"from": moment().format("YYYY-MM-DDTHH")}});
                };

                ////////////////////

                function updateNavigation() {
                    var currentIndex;

                    $scope.granularities.current = _.find($scope.granularities.items, function (granularity, index) {
                        currentIndex = index;
                        return granularity.key == _calendar.granularity;
                    });

                    $scope.granularities.next = $scope.granularities.items[currentIndex - 1] || null;
                    $scope.granularities.prev = $scope.granularities.items[currentIndex + 1] || null;

                }

                function setGranularity(g) {
                    _v.change({set: {"granularity": g.key}});
                }
            }
        };
    });